import { installKeyboardShortcutWatcher } from "@amzn/paragon-context/lib/KeyboardShortcutContext/installKeyboardShortcutWatcher";
// eslint-disable-next-line
import React, { useCallback, useEffect, useRef } from "react";


type Params = {
  id: string;
  defaultKeySequence: string[];
  description: string;
  onKeybindFired: () => void;
};

export function useKeyboardShortcut(
  id: string,
  defaultKeySequence: string[],
  description: string,
  onKeybindFired: () => void
) {
  return useKeyboardShortcuts([
    {
      defaultKeySequence,
      description,
      id,
      onKeybindFired
    }
  ]);
}

export function useKeyboardShortcuts(
  params: (Params | false | null | undefined)[]
) {
  const paramsRef = useRef(params);
  useEffect(() => {
    paramsRef.current = params;
  });

  const keyboardShortcutWatcherRef =
    useRef<ReturnType<typeof installKeyboardShortcutWatcher>>();

  const elRefHandler = useCallback(
    (el: HTMLDivElement | null) => {
      if (keyboardShortcutWatcherRef.current) {
        keyboardShortcutWatcherRef.current.uninstall();
      }

      if (!el) {
        return;
      }

      const keyboardShortcutWatcher = installKeyboardShortcutWatcher(el);
      keyboardShortcutWatcherRef.current = keyboardShortcutWatcher;

      for (const {
        defaultKeySequence,
        description,
        id,
        onKeybindFired
      } of paramsRef.current.filter((params): params is Params => !!params)) {
        keyboardShortcutWatcher.registerKeybind(
          id,
          defaultKeySequence,
          description,
          onKeybindFired
        );
      }
    },
    [JSON.stringify(params)]
  );

  return {
    paragonConsumerEl: <div ref={elRefHandler} />
  };
}
