import { KatAlert, KatBox, KatIcon, KatRadiobutton, KatSpinner, KatTable, KatTableBody, KatTableCell, KatTableRow, KatTextarea } from "@amzn/katal-react";
import React, { FunctionComponent, useState, useEffect } from "react";
import { WidgetFunctions } from "@amzn/paragon-context/lib/ParagonWidgetDataContext/models/WidgetFunctions";
import { AnalyticsFunctions } from "@amzn/paragon-context/lib/ParagonAnalyticsContext/models/AnalyticsFunctions";
import { getPredictedSOPs } from "../../service/actionPlan";
import { PredictSOPsFromCaseInfoResponseData, SOP } from "../../model/ActionPlanTypes";
import $style from './sop-details.module.scss';
import { getParagonDomain, logClickStreamData } from 'src/common/utils';

type Props = {
    paragonCaseId: string | undefined;
    paragonCaseSubject: string | undefined;
    widgetMethods: WidgetFunctions;
    changeSOPId: (data: string) => void;
    changeSopListFeedback: (data: string) => void;
    setIfSOPListed: (data: boolean) => void;
    isSOPPicked: boolean;
    clickStream: AnalyticsFunctions;
}

export const SOPDetails: FunctionComponent<Props> = ({
    paragonCaseId,
    paragonCaseSubject,
    widgetMethods,
    changeSOPId,
    changeSopListFeedback,
    setIfSOPListed,
    isSOPPicked,
    clickStream }) => {
    const [sopList, setSopList] = useState<SOP[]>([]);
    const [isloadingSOP, setIsloadingSOP] = useState(false);
    const [isError, setIsError] = useState(false);
    const [numberOfSOP, setNumberOfSOP] = useState<number>(3);
    const [isSopNotListed, setIsSopNotListed] = useState(false);
    const domain: string = getParagonDomain(window.location.origin);
    const standardError: string = "Sorry, something went wrong. The list of recommended SOPs cannot be generated at this time. Please follow standard procedures to address the case and try again later.";
    const [errorHeader, setErrorHeader] = useState('Error generating list of SOPs');
    const [errorMessage, setErrorMessage] = useState(standardError);

    useEffect(() => {
        setIsloadingSOP(true);
        if (!paragonCaseId) {
            return;
        }
        getPredictedSOPs(paragonCaseId, paragonCaseSubject, numberOfSOP, widgetMethods).then((response) => {
            if (response.success) {
                setIsError(false)
                const predictSOPsFromCaseInfoResponse: PredictSOPsFromCaseInfoResponseData = response.data;
                const sopList = predictSOPsFromCaseInfoResponse.sopList;
                const allSopList = sopList.concat({ title: 'SOP not listed', id: 'none', description: '' })
                setSopList(allSopList);
            } else {
                const message = response.message.toLowerCase();
                if (message.includes('failed to predict sops from case info')) {
                    setErrorHeader('Unable to generate SOP recommendations');
                    setErrorMessage('Currently we are unable to generate SOP recommendations for non-email cases. Please follow standard procedures to address the case.');
                }
                setIsError(true);
            }
        }).catch(() => {
            setIsError(true)
            console.log("SOP fetch failed");
        }).finally(() => {
            setIsloadingSOP(false);
        })
    }, [paragonCaseId])

    return (
        <div>
            <KatBox variant="white" className={$style.mainBox}>
                <h6>Select a recommended SOP</h6>
                {isloadingSOP &&
                    <KatSpinner variant="default" size="small"></KatSpinner>
                }
                {isError && <KatAlert variant='warning'
                    header={errorHeader}
                    description={errorMessage}
                // dismissed={isSOPPicked}
                />
                }
                {sopList && (
                    <div>
                        <KatTable className={$style.sopTable}>
                            <KatTableBody>
                                {sopList.map((sopDetails, index) => (
                                    <KatTableRow>
                                        <KatTableCell className={$style.katCellLabel}>
                                            <KatRadiobutton
                                                className={$style.sopRadioButton}
                                                name="katal-radio-group-sop-list"
                                                key={index}
                                                label={sopDetails.title}
                                                value={sopDetails.id}
                                                constraint-label={sopDetails.description}
                                                onChange={(e) => {
                                                    if ((e.target as HTMLInputElement).value === "none") {
                                                        setIsSopNotListed(true);
                                                        setIfSOPListed(false);
                                                    } else {
                                                        changeSOPId((e.target as HTMLInputElement).value);
                                                        setIsSopNotListed(false);
                                                        setIfSOPListed(true);
                                                    }
                                                }} />
                                        </KatTableCell>
                                        <KatTableCell className={$style.katCellLabel}>
                                            {sopDetails.id != 'none' &&
                                                <a href={domain + "/hz/codex?id=" + sopDetails.id}
                                                    target="_blank"
                                                    onClick={() => {
                                                        logClickStreamData(clickStream, "click", "action-plan-widget-sop-link-clicked", {
                                                            caseId: paragonCaseId,
                                                            sopId: sopDetails.id
                                                        });
                                                    }}
                                                >
                                                    <KatIcon name="launch" size="small" />
                                                </a>
                                            }
                                        </KatTableCell>
                                    </KatTableRow>
                                ))}
                            </KatTableBody>
                        </KatTable>
                        {isSopNotListed && <KatTextarea
                            className={$style.textArea}
                            placeholder="Enter a more relevant SOP link"
                            onKeyup={(e: any) => {
                                changeSopListFeedback(e.target.value);
                            }}
                        ></KatTextarea>
                        }
                    </div>
                )}
            </KatBox>
        </div>
    );
};