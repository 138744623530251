import { KatButton, KatPopover } from "@amzn/katal-react";
import React, { FunctionComponent } from "react";
import $style from './common-component.module.scss';
import { CancelPopover } from "./CancelPopover";

type Props = {
    handleNextButton: () => void;
    handleSubmitButton: () => void;
    handleBackButton: () => void;
    handleResolveButton: () => void;
    changeCancelReason: (data: string) => void;
    command: string;
    isSOPPicked: boolean;
    disableNext: boolean;
}

export const SelectionFooter: FunctionComponent<Props> = ({
    handleNextButton,
    handleSubmitButton,
    handleBackButton,
    handleResolveButton,
    changeCancelReason,
    command,
    isSOPPicked,
    disableNext }) => {

    return (
        <div>
            <KatButton
                className={$style.buttonDisplay}
                variant="secondary"
                label="Back"
                disabled
                onClick={() => handleBackButton()}
            />
            {command !== "END" ?
                <KatPopover
                    position="top-start"
                    triggerType="hover"
                    disabled={!isSOPPicked}
                >
                    <KatButton
                        className={$style.buttonDisplay}
                        variant="primary"
                        label="Next"
                        slot="trigger"
                        disabled={disableNext}
                        onClick={() => handleNextButton()}
                    />
                    <label>Currently, we are unable to generate steps for this SOP.
                        Please click the link icon to open the SOP in a new tab.</label>
                </KatPopover> : <KatButton
                    className={$style.buttonDisplay}
                    variant="primary"
                    label="Resolve"
                    onClick={() => handleResolveButton()}
                />}
            <KatPopover
                position="top-end"
                triggerType="click"
            >
                <KatButton
                    className={$style.buttonDisplay}
                    variant="tertiary"
                    label="Close"
                    slot="trigger"
                    disabled
                />
                <CancelPopover
                    handleSubmitButton={handleSubmitButton}
                    changeCancelReason={changeCancelReason}
                />
            </KatPopover>
        </div>
    );
};