import { KatAccordion, KatAccordionItem, KatCarouselImage, KatRadiobutton, KatSpinner, KatTile, KatTileContainer } from "@amzn/katal-react";
import React, { FunctionComponent, useState, useEffect } from "react";
import $style from './activity-list.module.scss';
import { getParagonDomain } from 'src/common/utils';

type Props = {
    actions: any;
    loading: boolean;
    onActivitySelection: (data: string) => void;
}

const SOPItems = ({ items }: any) => {
    if (!(items?.[0]?.children)) {
        items[0].children = items;
    }
    return items?.length && items.map((item) => renderItem(item))
}

const renderItem = (item) => {
    const { type, children, value, text, sop_id, src, href } = item;
    const domain: string = getParagonDomain(window.location.origin);

    switch (type) {
        case 'text':
            return <span>{value}</span>;
        case 'sop_ref':
            return <a href={domain + "/hz/codex?id=" + sop_id} target="_blank">{text}</a>;
        case 'list':
            return (
                <ul>
                    {
                        children?.map((child) => (
                            renderItem(child)
                        ))
                    }
                </ul>
            )
        case 'list_item':
            return (
                <li>
                    {
                        children.length && children?.map((child) => renderItem(child))
                    }
                </li>
            )
        case 'image':
            return (
                <>
                <KatTileContainer>
                    <KatTile image={src} />
                    </KatTileContainer>
                    {/* <label>{src}</label> */}
                </>
            )
        case 'link':
            return (
                <div>
                    {/* <label>{href}</label> */}
                    {/* <a href={href}/> */}
                </div>
            )

    }
}

export const ActivityList: FunctionComponent<Props> = ({ actions, loading, onActivitySelection }) => {

    console.log("actions::", actions);
    return (
        <div className={$style.activityBox}>
            {actions?.map((action, index) => {
                return (
                    <KatAccordionItem
                        className={$style.accordianBox}
                        label={`Task${index + 1}`}
                        expanded={index == actions.length - 1}
                    >
                        <label className={$style.taskLabel}>Complete the steps and select an outcome you arrived at after following the process</label>
                        <h6 className={$style.stepsList}>Steps</h6>
                        <SOPItems items={action?.sop_items} />
                        <div className={$style.taskLabel}></div>
                        <h6>Select outcome</h6>
                        <div>
                            {action?.outcome_map && Object.keys(action?.outcome_map || {}).map((outcomeMapKey) => (
                                <KatRadiobutton
                                    value={outcomeMapKey}
                                    disabled={index !== actions.length - 1}
                                    onChange={(e) => {
                                        console.log("VAlue:::", (e.target as HTMLInputElement).value);
                                        onActivitySelection((e.target as HTMLInputElement).value);
                                    } }
                                >
                                    {typeof (action?.outcome_map[outcomeMapKey]?.outcome) === "string" ?
                                        <span>{action?.outcome_map[outcomeMapKey]?.outcome}</span> :
                                        (action?.outcome_map[outcomeMapKey].outcome || [])?.map((outcomeItem) => renderItem(outcomeItem)
                                        )}
                                </KatRadiobutton>
                            ))}
                        </div>
                    </KatAccordionItem>);
            })}

            {loading &&
                <KatSpinner variant="default" size="small"></KatSpinner>}
        </div>
    );
};