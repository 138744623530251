import * as KatalMetrics from '@amzn/katal-metrics';
import KatalMetricsDriverSushi from '@amzn/katal-metrics-driver-sushi';
import KatalMetricsDriverArrayCollector from '@amzn/katal-metrics/lib/driver/KatalMetricsDriverArrayCollector';
import KatalMetricsDriverConsoleLogJson from '@amzn/katal-metrics/lib/driver/KatalMetricsDriverConsoleLogJson';

export enum MetricsType {
  // Transfer Widget metrics
  ADDING_CH_CONNECTION_ERROR = 'addingChConnectionError',
  TRANSFER_CASE_TRIGGER = 'transferCaseTrigger',
  AGENT_SEARCH_ERROR = 'agentSearchError',
  GET_WORKITEM_ID_ERROR = 'getWorkItemIdError',
  TRANSFER_BY_ATTRIBUTE_ERROR = 'transferByAttributeError',  
  TRANSFER_BY_EMAIL_ERROR = 'transferByEmailError',
  TRANSFER_BY_AGENT_ERROR = 'transferByAgentError',
  TRANSFER_BY_QUEUE_ERROR = 'transferByQueueError',
  TRANSFER_BY_EXTERNAL_QUEUE_ERROR = 'transferByExternalQueueError',
  TRANSFER_BY_QUEUE_DURATION = 'transferByQueueDuration',
  TRANSFER_BY_EMAIL_DURATION = 'transferByEmailDuration',
  TRANSFER_BY_ATTRIBUTE_DURATION = 'transferByAttributeDuration',
  TRANSFER_BY_EXTERNAL_QUEUE_DURATION = 'transferByExternalQueueDuration',
  TRANSFER_BY_AGENT_DURATION = 'transferByAgentDuration'
}

const metricsConsoleErrorHandler = (err: Error) => console.error(err);

const makeMetricsDriver = (): KatalMetrics.MetricsDriver => {
  if (process.env.NODE_ENV === 'test') {
    const metricsDriver = new KatalMetricsDriverArrayCollector();
    //  Attach to global window object so tests can see it
    (window as any).metricsDriver = metricsDriver;
    return metricsDriver;
  } else if (process.env.NODE_ENV !== 'production') {
    return new KatalMetricsDriverConsoleLogJson();
  } else {
    return new KatalMetricsDriverSushi.Builder()
      .withDomainRealm('prod', 'USAmazon')
      .withErrorHandler(metricsConsoleErrorHandler)
      .build();
  }
};

const makePublisher = (): KatalMetrics.Publisher => {
  const metricsDriver = makeMetricsDriver();
  const initialMetricsContext = new KatalMetrics.Context.Builder()
    .withSite('Paragon')
    .withServiceName('SPSEParagonAssociateFeatures')
    .build();
  return new KatalMetrics.Publisher(
    metricsDriver,
    metricsConsoleErrorHandler,
    initialMetricsContext,
  );
};

const initialMetricsPublisher = makePublisher();

export default initialMetricsPublisher;
