import { KatButton, KatRadiobutton, KatTextarea } from '@amzn/katal-react';
import React, { FunctionComponent, useEffect, useState } from 'react';
import $style from './common-component.module.scss';

type Props = {
    handleSubmitButton: () => void;
    changeCancelReason: (data: string) => void;
}

export const CancelPopover: FunctionComponent<Props> = ({ handleSubmitButton, changeCancelReason }) => {

    const cancelTypes = [
        'SOP not listed',
        'Incorrect steps',
        'Insufficient information to proceed',
        'Process is already known, no need to view steps',
        'Only needed the SOP name',
        'Other',
    ];

    useEffect(() => {
    })

    return (
        <div>
            <label>Choose a reason for not using action plan,</label>
            {cancelTypes.map((type) => (
                <KatRadiobutton
                    className={$style.cancelRadioButton}
                    label={type}
                    value={type}
                />
            ))}
            <div className={$style.cancelReason}>
            <label>Please share any additional comments or details</label>
            <KatTextarea
                className={$style.cancelText}
                placeholder='Enter comments'
                onKeyup={(e: any) => {
                    changeCancelReason(e.target.value);
                }}
            />
            <KatButton
                variant='primary'
                label='Submit'
                onClick={() => handleSubmitButton()}
            />
            </div>
        </div>
    );
};