import { html, property } from 'lit-element';
import { nothing } from 'lit-html';
import {
  KatLitElement,
  register,
  event,
  EventEmitter,
} from '../../shared/base';
import {
  WEBLAB_IDS,
  VISUAL_REFRESH_CLASSNAME,
  onBodyWeblabClassChange,
  offBodyWeblabClassChange,
} from '../../utils/weblab-helpers';
import baseStyles from '../../shared/base/base.lit.scss';
import accordionItemStyles from './accordion-item.lit.scss';

/**
 * @component {kat-accordion-item} KatalAccordionItem Accordion Items allow content to be hidden and broken into several collapsed sections. Customers have the ability to see one or more sections at a time. Accordion Items display introductory text with the rest hidden or collapsed.
 * @example SampleAccordionItem {"label": "My Accordion Item", "content": "<div>My Content to Hide</div>"}
 * @example WithBadge {"label": "My Accordion Item", "badge-label": "New", "content": "<div>My Content to Hide</div>"}
 * @guideline Do Content must be valid html <div>Hello World</div>
 * @guideline Do Use expandable sections beneath relevant items in the interface to reduce page length.
 * @guideline Do Only include non-actionable and secondary content in the collapsed area of the expandable sections.
 * @guideline Do Use sentence case (not title case)
 * @guideline Do Keep heading content short and actionable by avoiding articles (such as "a," "an," "the")
 * @guideline Dont Don’t hide error messages or critical information under an expandable section.
 * @guideline Dont Don't use long text for badge labels, badge label has a max width of 150px.
 * @theme flo
 * @slot label Contents will be used as the label (header text) section.
 * @slot badge Contents will be used as the header badge.
 * @slot default Contents will be used as the main collapsible content section.
 * @category Container
 * @a11y {keyboard}
 * @a11y {sr}
 * @a11y {contrast}
 */
@register('kat-accordion-item')
export class KatAccordionItem extends KatLitElement {
  /** Heading displayed for the accordion item */
  @property()
  label?: string;

  /** Determines if the content should be displayed */
  @property()
  expanded?: boolean;

  /**
   * Determines if the content should be remain expanded
   * within an accordion when it's siblings are expanded.
   */
  @property({ attribute: 'remains-expanded' })
  remainsExpanded?: boolean;

  /** Text to display in an optional badge */
  @property({ attribute: 'badge-label' })
  badgeLabel?: string;

  /**
   * The type of badge to display to the user
   * @enum {value} default Used for a generic status or state
   * @enum {value} success Items that have positive meaning
   * @enum {value} warning Items with problems or that have been removed
   * @enum {value} info Items that are actionable
   */
  @property({ attribute: 'badge-type' })
  badgeType?: string;

  /** Fires when item expands. */
  @event('expand', true)
  private _expand: EventEmitter;

  /** Fires when item is collapses. */
  @event('collapse', true)
  private _collapse: EventEmitter;

  static get styles() {
    return [baseStyles, accordionItemStyles];
  }

  connectedCallback(): void {
    super.connectedCallback();
    onBodyWeblabClassChange(() => this.requestUpdate());
  }

  disconnectedCallback(): void {
    super.disconnectedCallback();
    offBodyWeblabClassChange(() => this.requestUpdate());
  }

  // This is needed to handle focus when slotted labels/titles get clicked
  focusHeader(e) {
    e.preventDefault();
    this._shadow('.header').focus();
  }

  toggleExpanded() {
    if (this.expanded) {
      this.collapse();
    } else {
      this.expand();
    }
  }

  expand() {
    this.expanded = true;
    this._expand.emit();
  }

  collapse() {
    this.expanded = false;
    this._collapse.emit();
  }

  private getIconName() {
    if (
      document.body?.classList.contains(WEBLAB_IDS.group2) ||
      document.body?.classList.contains(VISUAL_REFRESH_CLASSNAME)
    ) {
      return this.getArrowDirection() === 'up' ? 'chevron-up' : 'chevron-down';
    }
    return 'play_arrow';
  }

  // TODO: After Visual Refresh experimentation, only up and down should be allowed
  private getArrowDirection() {
    if (
      document.body?.classList.contains(WEBLAB_IDS.group2) ||
      document.body?.classList.contains(VISUAL_REFRESH_CLASSNAME)
    ) {
      return this.expanded ? 'up' : 'down';
    }
    return this.expanded ? 'down' : 'right';
  }

  render() {
    return html`
      <button
        class="header"
        type="button"
        part="accordion-item-header"
        aria-expanded=${this.expanded ? 'true' : 'false'}
        @click=${this.toggleExpanded}
        @mousedown=${this.focusHeader}
      >
        <div class="header__text">
          <span class="label">
            <slot name="label" part="accordion-item-label">
              ${this.label ? this.label : nothing}
            </slot>
          </span>
          <div class="header__badge">
            <slot name="badge" part="accordion-item-badge">
              <slot name="badge" part="accordion-item-badge">${nothing}</slot>
              ${this.badgeLabel
                ? html`
                    <kat-badge
                      .type=${this.badgeType}
                      variant="default"
                      class="badge"
                    >
                      ${this.badgeLabel || nothing}
                    </kat-badge>
                  `
                : nothing}
            </slot>
          </div>
        </div>
        <div
          class="header__toggle"
          part="accordion-item-toggle"
          arrow="${this.getArrowDirection()}"
        >
          <slot name="indicator"
            ><kat-icon name=${this.getIconName()}></kat-icon
          ></slot>
        </div>
      </button>
      <div class="content">
        <slot part="accordion-item-content"></slot>
      </div>
    `;
  }
}
