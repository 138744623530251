import {issueSummaryContext} from "@amzn/paragon-context/lib/IssueSummaryContext";
import {IssueSummaryFunctions} from "@amzn/paragon-context/lib/IssueSummaryContext/models";
import {paragonReplyContext} from "@amzn/paragon-context/lib/ParagonReplyContext";
import {ReplyFunctions} from "@amzn/paragon-context/lib/ParagonReplyContext/models";
import {paragonWidgetDataContext} from "@amzn/paragon-context/lib/ParagonWidgetDataContext";
import {useParagonContext, useParagonContextConsumerEl} from "@amzn/paragon-ui-react";
// eslint-disable-next-line
import  React, {createContext, PropsWithChildren} from "react";
import {ParagonService} from "src/service/paragon.service";
import {TardisStorageService} from "src/service/storage.service";
import {TransferService} from "src/service/transfer.service";

interface ServiceContextInterface {
    paragonService: ParagonService,
    storageService: TardisStorageService,
    transferService: TransferService
}

const defaultServiceContext: ServiceContextInterface = {
    paragonService: new ParagonService({} as ReplyFunctions),
    storageService: new TardisStorageService({ proxyPost: () => {} }),
    transferService: new TransferService({} as IssueSummaryFunctions)
}

export const ServicesAPIContext = createContext<ServiceContextInterface>(defaultServiceContext);

export const ServiceAPIContextProvider = (props: PropsWithChildren) => {

    const el = useParagonContextConsumerEl();

    const {
        methods: widgetMethods
    } = useParagonContext(el, paragonWidgetDataContext);

    const {
        methods: replyFunctions
    } = useParagonContext(el, paragonReplyContext);

    const { methods: issueSummaryWidgetMethods } = useParagonContext(
        el,
        issueSummaryContext
      );

    const paragonService = new ParagonService(replyFunctions);
    const storageService = new TardisStorageService(widgetMethods);
    const transferService = new TransferService(issueSummaryWidgetMethods);

    const serviceContext: ServiceContextInterface = {
        paragonService,
        storageService,
        transferService
    };

    return <ServicesAPIContext.Provider value={serviceContext}>
        {props.children}
    </ServicesAPIContext.Provider>;
}