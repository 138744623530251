import { KatBox } from "@amzn/katal-react";
import React, { FunctionComponent } from "react";
import $style from './common/common-component.module.scss';

type Props = {
    command: string;
}

export const FinalBlurb: FunctionComponent<Props> = ({ command }) => {

    return (
        <div>
            <KatBox variant="white" className={$style.mainBox}>
                {command === "END"?
                <>
                <h6>Send reply to Seller</h6>
                <label>Inform seller that their request has been reviwed and provide appropriate decision and Reason code</label>
                </>
                : <label>Thank you for your feedback.</label>}
            </KatBox>
        </div>
    );
};