import { html } from 'lit-element';
import { KatLitElement, register } from '../../shared/base';
import baseStyles from '../../shared/base/base.lit.scss';
import styles from './accordion.lit.scss';

/**
 * @component {kat-accordion} KatalAccordion An accordion is a vertically stacked list of items within a container that can reveal or hide content on click and allows users to decide what to read and what to ignore.
 * @guideline Do Do only use kat-accordion-item elements as children of kat-accordion.
 * @guideline Do Do use remains-expanded when a user needs to compare multiple options or content available to them.
 * @guideline Dont Don't use remains-expanded when large areas of content inside the accordion structure make switching between the areas cumbersome or extend the overall page height 4x or greater than the desktop viewport.
 * @status Production
 * @theme flo
 * @slot default Should be filled with `kat-accordion-item` elements.
 * @subcomponent ./accordion-item.ts
 * @example Simple {"content": "
 *  <kat-accordion-item id=\"first\" label=\"First Item\"><p>First Item content</p></kat-accordion-item>
 *  <kat-accordion-item id=\"second\" label=\"Second Item\"><p>Second Item content</p></kat-accordion-item>
 *  <kat-accordion-item id=\"remains-expanded\" label=\"Remains Expanded Item\" remains-expanded><p>Remains Expanded Item content</p></kat-accordion-item>
 * "}
 * @example Nested {"content": "
 *  <kat-accordion-item id=\"1\" label=\"Top-level Item\"><p>Top-level item content</p></kat-accordion-item>
 *  <kat-accordion-item id=\"2\" label=\"Parent Item\"><kat-accordion><kat-accordion-item id=\"n1\" label=\"Nested Item\"><p>Nested item content</p></kat-accordion-item></kat-accordion></kat-accordion-item>
 *  <kat-accordion-item id=\"3\" label=\"Top-level Item\"><p>Top-level Item</p></kat-accordion-item>
 * "}
 * @a11y {keyboard}
 * @a11y {contrast}
 */
@register('kat-accordion')
export class KatAccordion extends KatLitElement {
  static get styles() {
    return [baseStyles, styles];
  }

  firstUpdated() {
    const observer = new MutationObserver(mutations => {
      this.domChange(mutations);
    });

    observer.observe(this, {
      childList: true,
      attributes: true,
      subtree: true,
    });
  }

  domChange(mutations) {
    const isKatAccordionItem = node => node.tagName === 'KAT-ACCORDION-ITEM';

    const validMutation = mutations.find(
      mutation =>
        mutation.target &&
        isKatAccordionItem(mutation.target) &&
        Array.from(this.childNodes).find(node =>
          node.isSameNode(mutation.target)
        ) &&
        mutation.target.expanded
    );

    if (validMutation) {
      Array.from(this.childNodes)
        .filter(isKatAccordionItem)
        .forEach(node => {
          if (!node.isSameNode(validMutation.target) && !node.remainsExpanded) {
            node.collapse();
          }
        });
    }
  }

  render() {
    return html`
      <div class="wrapper">
        <slot></slot>
      </div>
    `;
  }
}
